<template>
    <transition name="overlay">
        <article id="action-overlay" v-if="show">
            <div>
                <slot/>
            </div>
        </article>
    </transition>
</template>

<script>
export default {
    name: 'OverlayButtons',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less">
@import "../../assets/css/variables";

#action-overlay {
    --background-opacity: 30%;
    --background-color: var(--black-color);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(var(--background-color), var(--background-opacity));
    animation: background-fade-in var(--time-transition) var(--cubic-transition);

    #modal-overlay section & {
        --background-opacity: 80%;
        --background-color: var(--white-color);
        width: calc(100% - 40px);
    }

    &.overlay-leave-active {
        animation: background-fade-out var(--time-transition) var(--cubic-transition);

        button {
            animation: button-slide-out var(--time-transition) var(--cubic-transition) !important;
        }
    }

    div {
        --padding-right: calc(var(--container-padding) + var(--action-button-width) + 20px);
        position: absolute;
        bottom: calc(var(--menuitem-height) + 50px);
        right: var(--padding-right);
        display: flex;
        flex-direction: column-reverse;

        @media @laptop-screen {
            bottom: var(--container-padding);
        }
    }

    button {
        position: relative;
        margin-top: 10px;
        min-width: 170px;
        animation: button-slide-in var(--time-transition) var(--cubic-transition);

        &:nth-child(2) {
            transform: translateX(13px);
            animation: button-slide-in-second calc(var(--time-transition) * 1.25) var(--cubic-transition);
        }

        &:nth-child(3) {
            transform: translateX(26px);
            animation: button-slide-in-third calc(var(--time-transition) * 1.5) var(--cubic-transition);
        }
    }
}

@keyframes button-slide-in {
    0% {
        opacity: 0;
        transform: translate3d(30px, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes button-slide-in-second {
    0% {
        opacity: 0;
        transform: translate3d(43px, 0, 0);
    }
    25% {
        opacity: 0;
        transform: translate3d(43px, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(13px, 0, 0);
    }
}

@keyframes button-slide-in-third {
    0% {
        opacity: 0;
        transform: translate3d(56px, 0, 0);
    }
    25% {
        opacity: 0;
        transform: translate3d(56px, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(26px, 0, 0);
    }
}

@keyframes button-slide-out {
    100% {
        opacity: 0;
        right: -40px;
    }
}
</style>
